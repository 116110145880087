
import React, { useState, useEffect } from "react";
import { Card, Stack, Button, LinearProgress, Typography, TextField, Alert } from "@mui/material";
import { db, storage } from "../config/firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Timestamp, collection, doc, where, query, arrayUnion, updateDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"
import { opCategories } from "./MyData.js";

function WebContentUpload({ userId, onLogin, onLogout, selectedCategory }) {
  
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [dbDataUpdated, setDbDataUpdated] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [webUrl, setWebUrl] = React.useState("");
  const [urlContent, setUrlContent] = React.useState("");

  // const [fileUrl, setFileUrl] = React.useState(null);

  function handlecategoryChange(event, newValue) {
    // setSelectedcategory(newValue);
  }

  // const fetchUrlContent = async () => {
  //   try {
  //     const response = await fetch('https://www.google.com/'); 
  //     console.log ("RESP:", response)
  //     const jsonData = await response.json();
  //     setUrlContent(jsonData) 
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  
  async function addUrlToDb() {
    const opData = {
      userId: userId,
      title: title,
      selectedCategory: selectedCategory,
      opinion: '',
      webUrl: webUrl,
      hasVoted: [],
      urlContent: urlContent,
      numVotes: 0,
      rating: 0,
      submissionDate: new Date(),
    };
    try {
      const docRef = await addDoc(collection(db, "contentRatingTable"), opData);
      setDbDataUpdated(!dbDataUpdated); // toggle
      console.log("Success!");
    } catch (e) {
      console.log("ERROR:", "db:", db, "Data:", opData);
      console.error("Error adding document: ", e);
    }
  }
 
  // const handleClose = () => {
  //   setShowLoginAlert(false);
  // };

  useEffect(() => {
    if (!showLoginAlert) {
      if (!userId) setShowLoginAlert(true);
      else {
        setShowLoginAlert(false);
      }
    }
  }, [
    showLoginAlert,
    selectedCategory,
    webUrl,
    title, 
    userId,
  ]);

  const handleUpload = () => {
    // fetchUrlContent()
    addUrlToDb();
    setWebUrl("");
    setUrlContent("")
    setTitle("");
  };

  function checkLoggedIn() {
    if (!userId || !selectedCategory) setShowLoginAlert(true);
  }
  function loginAlert() {
    return (
      <>
        {showLoginAlert && (
          <Alert severity="info">
            <span style={{ fontSize: 14, fontStyle: "italic" }}>
              Login to upload
            </span>
          </Alert>
        )}
      </>
    );
  }
  function getUrl() {
    return (
      <Card
        elevation={10}
        style={{
          align: "left",
          justifyContent: "flex-start",
          marginTop: "15px",
          marginBottom: "20px",
          marginLeft: "7px",
          marginRight: "5px",
          width: "300px",
          height: "460px",
        }}
      >
        <Stack spacing="4px">
          {userId && selectedCategory && (
            <>
              <TextField
                label="Enter Title:"
                style={{
                  marginLeft: "10px",
                  marginTop: "1em",
                  width: "280px",
                }}
                inputProps={{
                  maxLength: 100,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign:"justify"
                  },
                }}
                variant="outlined"
                multiline={true}
                rows={2}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                helperText={title ? `${title.length}/100` : null}
              />
              {title && (
                <>
                 <TextField
                             label="Comments, Content Summary:"
                             style={{
                               marginLeft: "10px",
                               marginTop: "1em",
                               width: "280px",
                             }}
                             inputProps={{
                               maxLength: 300,
                               style: {
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 textAlign: "justify"
                               },
                             }}
                             variant="outlined"
                             multiline={true}
                             rows= {5}
                             value={urlContent}
                             onChange={(event) => setUrlContent(event.target.value)}
                             helperText={urlContent? `${urlContent.length}/300` : null}
                           />

                             <TextField
                             label="Enter Link:"
                             textAlign="justify"
                             style={{
                               marginLeft: "10px",
                               marginTop: "1em",
                               marginBottom: "0.5em",
                               width: "280px",
                             }}
                             inputProps={{
                               maxLength: 100,
                               style: {
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 whiteSpace: "nowrap",
                               },
                             }}
                             variant="outlined"
                             multiline="true"
                             value={webUrl}
                             onChange={(event) => setWebUrl(event.target.value)}
                             helperText={webUrl ? `${webUrl.length}/100` : null}
                           />
            <Button
            variant="contained"
            color="secondary"
            onClick={handleUpload}
            style={{
              marginLeft: "10px",
              marginBottom: "5px",
              marginRight: "5px",
            }}
          >
            SUBMIT
          </Button>
          </>
          )}
            </>
          )}
        </Stack>

        {showLoginAlert && loginAlert()}
      </Card>
    );
  }
  return getUrl();
}

export default WebContentUpload
