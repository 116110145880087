import React, { useState, useEffect } from "react";
import { Alert, Card, TextField, Stack, Button, Box } from "@mui/material";
import { auth, db } from "../config/firebase.js";
import {
  collection,
  doc,
  where,
  query,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import Login from "./Login.js";
import ProGroupChatNew from "./ProGroupChatNew.js"
import ProGroupChatMember from "./ProGroupChatMember.js"
import ProCorpPrivateQuizAdmin from "./ProCorpPrivateQuizAdmin.js"
import ProCorpPrivateQuizUser from "./ProCorpPrivateQuizUser.js"
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Tester from "./Tester.js"
import { use } from "react";

function ProGroupChat({ userId, onLogin, onLogout }) {
  const [pvtPoll, setPvtPoll] = useState(true)
  const [pvtQuiz, setPvtQuiz] = useState(false)
  const [groupChatId, setGroupChatId] = useState("");
  const [groupChatTitle, setGroupChatTitle] = useState("");
  const [groupChatAdmin, setGroupChatAdmin] = useState('');
  const [userType, setUserType] = useState("user");
  const [showAlert, setShowAlert] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showDocIdAlert, setShowDocIdAlert] = useState(false);
  const [newChat, setNewChat] = useState(false);

  const [groupChatIdError, setGroupChatIdError] = useState(false);
  const [showEnterGroupChatId, setShowEnterGroupChatId] = useState(false);
  const [showEnterNewGroupChatId, setShowEnterNewGroupChatId] = useState(false);
  const [correctGroupChatIdEntered, setCorrectGroupChatIdEntered] = useState(false);
  const [dbDataUpdated, setDbDataUpdated] = useState(false);
  // const [newDocId, setNewDocId] = useState(''); //of new group chat entry
  const button1Color = "#5B76D7";
  const button2Color = "#f5f5f5";
  const buttonSelectedColor = "#af52bf";
  const [selectedButton, setSelectedButton] = useState(1);

  useEffect(() => {
    const fetchUserType = async () => {
      if (userId) {
        await checkUserType(userId);
      }
    };
    fetchUserType()
  }, [
    userId,
    groupChatId,
    selectedButton,
    pvtPoll,
    // newDocId,
    groupChatAdmin,
    pvtQuiz,
    correctGroupChatIdEntered,
    showEnterGroupChatId,
    dbDataUpdated
  ]);

  const checkIfGroupChatIdCorrect = async (groupChatId) => {
    try {
      const overviewQuery = query(
        collection(db, "groupChatTable"),
        where("groupChatId", "==", groupChatId),
        // where("admin", "==", userId)
      );
      const overviewSnapshot = await getDocs(overviewQuery);
      if (overviewSnapshot.empty) {
        return false;
      } else {
        const doc = overviewSnapshot.docs[0];
        const data = doc.data();
        setGroupChatTitle(data.title)
        setGroupChatAdmin(data.admin)
        return true;
      }
    } catch (error) {
      console.error("Firebase error: ", error);
    }
  };

  const handleNewGroupChatTitleEntered = async () => {

    try {
      const data = {
        admin: userId,
        title: groupChatTitle
      };
      const docRef = await addDoc(collection(db, 'groupChatTable'), data);
      const newDocId = docRef.id;
      // setNewDocId(newDocId);
      setGroupChatId(newDocId)
      setShowDocIdAlert(true)
      setNewChat(true);
      await updateDoc(docRef, { groupChatId: newDocId });
      setDbDataUpdated(!dbDataUpdated); // toggle
    }
    catch (error) {
      console.error("Error adding document:", error);
    }
    setShowEnterGroupChatId(false);
    setShowEnterNewGroupChatId(false);
    setShowLoginAlert(false);
    setGroupChatIdError(false);
    setSelectedButton(1);
  };

  const handleGroupChatIdEntered = async () => {

    const isGroupChatIdCorrect = await checkIfGroupChatIdCorrect(groupChatId);
    if (!isGroupChatIdCorrect) {
      setGroupChatId("");
      return setGroupChatIdError(true);
    }
    setShowEnterGroupChatId(false);
    setShowEnterNewGroupChatId(false);
    setShowLoginAlert(false);
    setGroupChatIdError(false);
    setNewChat(false)
    if (!(selectedButton === 1)) {
      setSelectedButton(1);
    } else {
      setSelectedButton(0);
    }
    setCorrectGroupChatIdEntered(true);
  };

  const handleClose = (type) => {
    if (type === 'Error') {
      setGroupChatIdError(false);
      setShowLoginAlert(false)
    } else
      setShowDocIdAlert(false);
  };


  const checkUserType = async (userId) => {

    if (userId != null) {
      try {
        const overviewQuery = query(
          collection(db, "groupChatTable"),
          where("groupChatId", "==", groupChatId),
          where("admin", "==", userId)
        );

        const overviewSnapshot = await getDocs(overviewQuery);

        if (overviewSnapshot.empty) {
          setUserType("user");
        } else {
          setUserType("admin");
        }
      } catch (error) {
        console.error("Firebase error: ", error);
        setUserType('')
      }
    } else setUserType('')
  };

  function renderGroupChatTitle() {
    return (<Card
      elevation={5}
      sx={{
        width: "334px",
        height: '40px',
        marginTop: "1em",
        marginBottom: "1em",
        marginLeft: "8px",
        marginRight: "6px",
        fontSize: '16px',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        color: '#af52bf',
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
      }}
    >
      {groupChatTitle}
    </Card>
    )
  }
  function renderNewGroupChatId() {
    return (
      showDocIdAlert && (
        <Alert
          severity="info"
          action={
            <IconButton onClick={() => handleClose('')}>
              <CloseIcon />
            </IconButton>
          }
        >
          <span style={{ fontStyle: "italic" }}>
            Share this Id with your group members: {groupChatId}
          </span>
        </Alert>
      )
    )
  }
  // function handlePolls() {
  //   setPvtPoll(true)
  //   setPvtQuiz(false)
  //   if (!(selectedButton === 1)) {
  //     setSelectedButton(1);
  //   } else {
  //     setSelectedButton(0);
  //   }
  // }
  // function handleQuizzes() {
  //   setPvtPoll(false)
  //   setPvtQuiz(true)
  //   if (!(selectedButton === 2)) {
  //     setSelectedButton(2);
  //   } else {
  //     setSelectedButton(0);
  //   }
  // }
  // function corpPollorQuiz() {
  //   return (
  //     <>
  //       {/* <Card
  //         elevation={10}
  //         sx={{
  //           width: "320px",
  //           height: "60px",
  //           marginTop: "1em",
  //           marginBottom: "1em",
  //           marginLeft: "6px",
  //           // marginRight: "4px",
  //         }}
  //       > */}
  //       <Stack direction='row'>
  //         <Button
  //           variant="contained"
  //           backgroundcolor="#333"
  //           onClick={() => {
  //             handlePolls()
  //           }}
  //           style={{
  //             border: "none",
  //             width: "165px",
  //             height: "40px",
  //             marginLeft: "6px",
  //             marginTop: "4px",
  //             marginBottom: "4px",
  //             borderRadius: 7,
  //             fontWeight: "bold",
  //             backgroundColor:
  //               selectedButton === 1 ? buttonSelectedColor : button2Color,
  //             color: selectedButton === 1 ? "white" : "grey",
  //             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  //             textTransform: "none",
  //             //backgroundColor: "#5B76D7",
  //             // backgroundColor: "#D1D1D1",
  //           }}
  //         >
  //           POLLS
  //         </Button>
  //         <Button
  //           variant="contained"
  //           backgroundcolor="#333"
  //           onClick={() => {
  //             handleQuizzes()
  //           }}
  //           style={{
  //             border: "none",
  //             width: "165px",
  //             height: "40px",
  //             marginLeft: "4px",
  //             marginRight: "10px",
  //             marginTop: "4px",
  //             marginBottom: "4px",
  //             borderRadius: 7,
  //             fontWeight: "bold",
  //             // color: "white",
  //             backgroundColor:
  //               selectedButton === 2 ? buttonSelectedColor : button2Color,
  //             color: selectedButton === 2 ? "white" : "grey",
  //             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  //             textTransform: "none",
  //             //backgroundColor: "#5B76D7",
  //             // backgroundColor: "#D1D1D1",
  //           }}
  //         >
  //           QUIZZES
  //         </Button>
  //       </Stack>
  //       {/* </Card > */}
  //     </>
  //   )
  // }

  const getNewGroupChatTitle = () => {
    return (
      <>
        <TextField
          label="Enter title, e.g., Stop Looking for Aliens"
          variant="outlined"
          sx={{ marginLeft: "5px", marginRight: "5px", marginTop: "10px", width: '98%' }}
          value={groupChatTitle}
          onChange={(e) => setGroupChatTitle(e.target.value)}
        />
        <Button
          variant="contained"
          backgroundcolor="#333"
          // endIcon={<SendIcon backgroundColor="grey" />}
          style={{
            border: "none",
            width: "100px",
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 28,
            color: "black",
            backgroundColor: "#D1D1D1",
          }}
          onClick={handleNewGroupChatTitleEntered}
        >
          Save
        </Button>
      </>
    )
  };
  const handleNewClick = () => {
    if (userId) {
      setShowEnterNewGroupChatId(true)
      setShowEnterGroupChatId(false);
      setCorrectGroupChatIdEntered(false)
      setGroupChatTitle('')
      setNewChat(false)
      setSelectedButton(1)
    } else {
      setShowLoginAlert(true);
      // setDialog1(true);
    }
  };
  const handleExistingClick = () => {
    setShowEnterGroupChatId(true);
    setCorrectGroupChatIdEntered(false)
    setShowEnterNewGroupChatId(false)
    setGroupChatId('')
    setNewChat(false)
    // setNewDocId('')
    setSelectedButton(2)
  }

  return (
    <Box>
      <Card
        elevation={10}
        sx={{
          flexDirection: 'column',  // Stacks children vertically
          justifyContent: 'space-between', // Ensures proper spacing
          display: 'flex',          // Enables flexbox
          // justifyContent: 'center', // Centers items horizontally
          alignItems: 'center',
          width: "334px",
          marginTop: "1em",
          marginBottom: ".25em",
          marginLeft: "8px",
          marginRight: "4px",
        }}
      >
        <Stack direction='row'>
          <Button
            variant="contained"
            backgroundcolor="#333"
            // endIcon={<SendIcon backgroundColor="grey" />}
            onClick={handleNewClick}
            style={{
              border: "none",
              width: "100px",
              marginLeft: "8px",
              marginTop: "10px",
              marginBottom: '10px',
              borderRadius: 28,
              backgroundColor:
                selectedButton === 1 ? buttonSelectedColor : button2Color,
              color: selectedButton === 1 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              textTransform: "none",
              // color: "black",
              // backgroundColor: "#D1D1D1",
            }}
          >
            NEW
          </Button>
          <Button
            variant="contained"
            backgroundcolor="#333"
            // endIcon={<SendIcon backgroundColor="grey" />}
            onClick={handleExistingClick}
            style={{
              border: "none",
              width: "100px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: '10px',
              marginRight: '8px',
              borderRadius: 28,
              // color: "black",
              backgroundColor:
                selectedButton === 2 ? buttonSelectedColor : button2Color,
              color: selectedButton === 2 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              textTransform: "none",
              // backgroundColor: "#D1D1D1",
            }}
          >
            EXISTING
          </Button>
        </Stack>

        {showEnterNewGroupChatId && (getNewGroupChatTitle())}
        {renderNewGroupChatId()}

        {showEnterGroupChatId ? (
          <div>
            <TextField
              label="Enter Group ID"
              variant="outlined"
              sx={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
              value={groupChatId}
              onChange={(e) => setGroupChatId(e.target.value)}
            />
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              style={{
                border: "none",
                width: "100px",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: 28,
                color: "black",
                backgroundColor: "#D1D1D1",
              }}
              onClick={handleGroupChatIdEntered}
            >
              Save
            </Button>

          </div>
        ) : (null)
        }
        {showLoginAlert ?
          <Alert
            severity="error"
            action={
              <IconButton onClick={() => handleClose('Error')}>
                <CloseIcon />
              </IconButton>
            }
          >
            <span style={{ fontStyle: "italic" }}>
              Login first...
            </span>
          </Alert>
          : null
        }

        {showEnterGroupChatId && groupChatIdError && (
          <Alert
            severity="error"
            action={
              <IconButton onClick={() => handleClose('Error')}>
                <CloseIcon />
              </IconButton>
            }
          >
            <span style={{ fontStyle: "italic" }}>
              Enter the correct Group ID, or contact your adminstrator
            </span>
          </Alert>
        )}
      </Card>

      {renderGroupChatTitle()}
      {newChat ?
        < ProGroupChatNew userId={userId} db={db} groupChatTitle={groupChatTitle} groupChatId={groupChatId} />
        : null
      }
      {correctGroupChatIdEntered && !userId && (
        <Login userId={userId} onLogin={onLogin} landingPage={'private'} />)}

      {correctGroupChatIdEntered && userId && (
        <>
          <ProGroupChatMember userId={userId} db={db} groupChatTitle={groupChatTitle} groupChatId={groupChatId} />
        </>
      )}
    </Box>
  );
}

export default ProGroupChat;
