import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Container,
  Typography,
  Toolbar,
  Stack,
  Card,
  IconButton,
} from "@mui/material";
import enzo from "./enzo.jpg";
import Login from "./Login";
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import LoginPage from "./LoginPage"
import AboutPage from "./AboutPage";

function TopAppBar({ onLogin, onLogout, companyName, userId }) {
  // const navigate = useNavigate();
  // const location = useLocation()

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showAboutForm, setShowAboutForm] = useState(false)
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showAbout, setShowAbout] = useState(false)

  // const handleLoginClick = () => {
  //   setShowLoginForm(!showLoginForm);
  // };

  // const handleShowAboutClick = () => {
  //   setShowAbout(!showAbout);

  // };

  // const handleLoginCancel = () => {
  //   setShowLoginForm(false);
  // };

  // useEffect(() => { }, [location])

  return (

    <Box>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12}>
          <Card>
            <AppBar
              dense
              position="static"
              sx={{
                backgroundColor: "#5B76D7",
                // overflowX: "auto",
                overflowY: 'hidden'
              }}
            >
              <Toolbar sx={{
                display: "flex",
                // minWidth: "max-content", // Ensures content doesn't shrink or get cut off
              }}>

                <IconButton size="medium" edge="start" color="inherit">
                  <img src={enzo} alt="logo" width="50" height="50" />
                </IconButton>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  flexGrow={1}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography
                      color="inherit"
                      variant="h6"
                      component={"div"}
                      sx={{ fontWeight: "bold", fontSize: "16px" }}
                    >
                      Trupollz
                    </Typography>
                    <Typography
                      component={"div"}
                      color="inherit"
                      sx={{ fontStyle: "italic", fontSize: "15px" }}
                    >Real-time polls on Current Affairs, CX, Web Content, Opinions or...your thing
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={0.5}
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Typography
                        component={"div"}
                        color="inherit"
                        sx={{ fontSize: "12px", fontWeight: "bold", width: "110px" }}
                      >
                        {companyName}
                      </Typography>
                      <Typography
                        component={"div"}
                        color="inherit"
                        sx={{ fontSize: "12px", fontWeight: "bold", width: "110px" }}
                      >
                        {userId}
                      </Typography>
                      {userId ? (
                        <Link to="/">
                          <Button sx={{ color: 'white', width: "110px", ml: "8px" }}
                            onClick={onLogout}
                          >
                            Logout
                          </Button>
                        </Link>
                      ) : (
                        <Link to="/login">
                          <Button sx={{ color: 'white', width: "110px", ml: "10px" }} >
                            Login
                          </Button>
                        </Link>
                      )}
                      <Link to="/opinion">
                        <Button sx={{ color: 'white', fontWeight: 'bold', width: "110px", ml: "10px" }} >
                          OPINION
                        </Button>
                      </Link>
                      <Link to="/contentRating">
                        <Button sx={{ color: 'white', width: "110px", ml: "10px" }} >
                          Rate Web Content
                        </Button>
                      </Link>
                      <Link to="/corp">
                        <Button sx={{ color: 'white', width: "130px", ml: "8px" }}>
                          CX, Corp, EDU, GROUP CHAT
                        </Button>
                        <Link to="/">
                          <Button sx={{ color: 'white', width: "110px", ml: "8px" }}>
                            Polls, Ratings
                          </Button>
                        </Link>
                      </Link>
                      <Link to="/about">
                        <Button
                          // color="inherit"
                          sx={{ color: 'white', width: "80px", ml: "8px" }}
                        >
                          About
                        </Button>
                      </Link>
                    </Box>
                  </Stack>
                </Stack>
              </Toolbar>
            </AppBar>

          </Card>

          {/* {(showLoginForm || showRegisterForm) && !userId && (

        <LoginPage />
      )}

      {showAbout && (
        <AboutPage showAbout={showAbout} />
      )} */}
        </Grid>
      </Grid>
    </Box >
  );
}
export default TopAppBar;
