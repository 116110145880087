
import React, { useState, useEffect } from "react";
import { Card, Stack, Button, LinearProgress, Typography, TextField, Alert } from "@mui/material";
import { db, storage } from "../config/firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Timestamp, collection, doc, where, query, arrayUnion, updateDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"
import { opCategories } from "./MyData";

function OpinionUpload({ userId, onLogin, onLogout, selectedCategory }) {
  const [file, setFile] = useState(null);
  const [uploadFileSelected, setUploadFileSelected] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  // const [selectedCategory, setSelectedcategory] = React.useState(null);
  const [dbDataUpdated, setDbDataUpdated] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [uploadUrl, setUploadUrl] = React.useState("");

  // const [fileUrl, setFileUrl] = React.useState(null);

  function handlecategoryChange(event, newValue) {
    // setSelectedcategory(newValue);
  }

  async function addOpinionToDb(opin, url) {
    const opData = {
      userId: userId,
      title: title,
      selectedCategory: selectedCategory,
      opinion: opin,
      opinionUrl: url,
      hasVoted: [],
      numVotes: 0,
      rating: 0,
      submissionDate: new Date(),
    };
    try {
      const docRef = await addDoc(collection(db, "opinionsTable"), opData);
      setDbDataUpdated(!dbDataUpdated); // toggle
      console.log("Success!");
    } catch (e) {
      console.log("ERROR:", "db:", db, "Data:", opData);
      console.error("Error adding document: ", e);
    }
    // setSelectedcategory(null)
  }
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadFileSelected(!uploadFileSelected); //toggle
  };

  // const handleClose = () => {
  //   setShowLoginAlert(false);
  // };

  useEffect(() => {
    if (!showLoginAlert) {
      if (!userId) setShowLoginAlert(true);
      else {
        setShowLoginAlert(false);
      }
    }
  }, [
    showLoginAlert,
    selectedCategory,
    file,
    title,
    userId,
    uploadFileSelected,
  ]);

  const fetchFileContent = (url) => {
    fetch(url)
      .then((response) => response.text()) // Assuming it's a text file
      .then((text) => {
        addOpinionToDb(text, url);
      })
      .catch((error) => {
        console.error("Error fetching file content:", error);
      });
  };

  const handleUpload = () => {
    if (!file) {
      console.log("file error if null:", file);
      return;
    }
    const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9._-]/g, "_");
    const path = `uploads/${sanitizedFileName}`;

    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // setFileUrl(downloadURL)

          fetchFileContent(downloadURL);
          // addOpinionToDb(downloadURL)
          // setSelectedcategory(null)
          setTitle("");
          setFile(null);
          setProgress(0);
        });
      }
    );
  };
  function checkLoggedIn() {
    if (!userId || !selectedCategory) setShowLoginAlert(true);
  }
  function loginAlert() {
    return (
      <>
        {showLoginAlert && (
          <Alert severity="info">
            <span style={{ fontSize: 14, fontStyle: "italic" }}>
              Login to upload
            </span>
          </Alert>
        )}
      </>
    );
  }
  function getInputFile() {
    return (
      <Card
        elevation={10}
        style={{
          align: "left",
          justifyContent: "flex-start",
          marginTop: "15px",
          marginBottom: "20px",
          marginLeft: "7px",
          marginRight: "5px",
          width: "250px",
          height: "200px",
        }}
      >
        <Stack spacing="4px">
          {/* {userId && (
            <Autocomplete
              value={selectedCategory}
              variant="filled"
              style={{ marginLeft: "3px", marginTop: "1em", width: 245 }}
              color="default"
              options={opCategories}
              onChange={handlecategoryChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="filled"
                />
              )}
            ></ Autocomplete>
          )} */}

          {userId && selectedCategory && (
            <>
              <TextField
                label="Enter Title:"
                textAlign="justify"
                // InputLabelProps={{
                //   style: { fontWeight: 'bold' },
                // }}
                style={{
                  marginLeft: "3px",
                  marginTop: "1em",
                  width: "245px",
                }}
                inputProps={{
                  maxLength: 300,
                  style: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
                variant="outlined"
                multiline="true"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                helperText={title ? `${title.length}/300` : null}
              />
              <Typography
                sx={{ paddingBottom: "4px", color: "blue", paddingTop: "8px" }}
              >
              </Typography>
              <input
                accept=".txt"
                style={{ marginTop: "5px", display: "none" }}
                id="upload-file"
                type="file"
                onClick={checkLoggedIn}
                onChange={handleFileChange}
              />
              <label htmlFor="upload-file">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#5B76D7",
                    fontWeight: "bold",
                    marginTop: "6px",
                    marginBottom: "6px",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                  component="span"
                >
                  Select File (.txt only)
                </Button>
              </label>
            </>
          )}
          
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUpload}
            disabled={!file}
            style={{
              marginLeft: "6px",
              marginBottom: "5px",
              marginRight: "5px",
            }}
          >
            Upload TXT File
          </Button>
          {progress > 0 && (
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ height: "0.25in" }}
            />
          )}
        </Stack>

        {showLoginAlert && loginAlert()}
      </Card>
    );
  }
  return getInputFile();
}

export default OpinionUpload;
