import React, { useState, useEffect } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Card,
  Alert,
  Box,
  Popover,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  IconButton,
  Button,
  FormControl,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Snackbar
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import { collection, doc, where, query, updateDoc, deleteDoc, getDocs, addDoc } from "firebase/firestore"
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { ThumbUp, ThumbDown, CollectionsBookmarkOutlined } from "@mui/icons-material";
import defaultPic from "./enzo.jpg";
import { CriteriaDisplay } from "./Ratings";
import { db } from "../config/firebase"

function ProGroupChatMember({ userId, onLogin, onLogout }) {

  const [uId, setUId] = useState(userId);
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM notation
  };
  // const [showAlert, setShowAlert] = useState(true);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showDelDisallowAlert, setShowDelDisallowAlert] = useState(false);
  const [mouseRowChanged, setMouseRowChanged] = useState(false)
  // const [dialog1, setDialog1] = useState(false);
  // const [dialog2, setDialog2] = useState(false);
  const [likesUpdated, setLikesUpdated] = useState(false);
  const [opinion, setOpinion] = React.useState("");
  const [results, setResults] = React.useState([]);
  // const [approvalInSelectedRow, setApprovalInSelectedRow] = useState([0]);
  const [dbDataUpdated, setDbDataUpdated] = useState(false);

  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedCategoryLength, setSelectedCategoryLength] = React.useState(5);

  const [selectedRow, setSelectedRow] = useState(null);
  const [rowCount, setRowCount] = React.useState(0);
  const [showForm, setShowForm] = React.useState(false);
  const [likes, setLikes] = React.useState(0);
  const [sort, setSort] = useState({ column: "Count", order: "desc" });
  const [anchor, setAnchor] = useState(null);
  const [currentKeyprop, setCurrentKeyprop] = useState("Nothing here");
  const [currentPic, setCurrentPic] = useState(defaultPic);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (inputIndex) => {
    const index = inputIndex + page * rowsPerPage;
    const newResults = [...results];
    const rowId = results[index].id;
    const name = results[index].Company;

    if (results[index].UserId !== userId && userId !== "rpaplus") {
      setShowDelDisallowAlert(true);
      // setDialog2(true);
    } else {
      try {
        // console.log ("row:", rowId)
        // Delete the document with the specified ID
        await deleteDoc(doc(db, "groupChatOverview", rowId));

        newResults.splice(index, 1);
        setResults(newResults);
        // const overviewQuery = query(
        //   collection(db, "groupChatOverview"),
        //   where("Company", "==", name)
        // );
        // const overviewSnapshot = await getDocs(overviewQuery);
        // if (overviewSnapshot.empty) {
        //   const detailsQuery = query(
        //     collection(db, "groupChatDetails"),
        //     where("Company", "==", name)
        //   );
        //   const detailsSnapshot = await getDocs(detailsQuery);

        //   detailsSnapshot.forEach(async (detailDoc) => {
        //     await deleteDoc(detailDoc.ref);
        //   });
        // }
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  const sortedResults = results.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.column] > b[sort.column] ? 1 : -1;
    } else {
      return b[sort.column] > a[sort.column] ? 1 : -1;
    }
  });

  const dataToDisplay = sortedResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const groupChatTableRef = collection(db, "groupChatTable");

  const fetchData = async () => {

    try {
      const tableSnapshot = await getDocs(groupChatTableRef);
      const tableData = tableSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(tableData[0].categories.map((item) => item.category))
      const groupChatOverviewRef = collection(db, "groupChatOverview")
      const overviewSnapshot = await getDocs(groupChatOverviewRef);
      const overviewData = overviewSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResults(overviewData);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setUId(userId);
    fetchData();
    if (!userId && showForm) {
      setShowForm(false)
    }
  }, [
    userId,
    showForm,
    likes,
    sort,
    mouseRowChanged,
    selectedRow,
    // selectedCompany,
    dbDataUpdated,
    // approvalInSelectedRow,
    opinion,
    likesUpdated,
    rowCount,
  ]);

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
      setDbDataUpdated(!dbDataUpdated); // toggle
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function addOrUpdateDocument(collectionName, data, rowId) {

    try {
      let q;
      if (rowId) {
        q = query(collection(db, collectionName), where("id", "==", rowId));
      } else {
        q = query(
          collection(db, collectionName),
          where("chat", "==", data.chat)
        );
      }
      const querySnapshot = await getDocs(q);
      console.log("data:", data, "snapshot:", querySnapshot)

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      } else {
        await addDoc(collection(db, collectionName), data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      }
    } catch (error) {
      console.error("Error adding or updating document:", error);
    }
  }

  // async function updateCXRating(company, CXRating) {
  //   const overviewQuery = query(
  //     collection(db, "groupChatOverview"),
  //     where("Company", "==", company)
  //   );
  //   const overviewSnapshot = await getDocs(overviewQuery);
  //   if (!overviewSnapshot.empty) {

  //     for (const doc of overviewSnapshot.docs) {
  //       await updateDoc(doc.ref, { CXRating: CXRating });
  //     }
  //     setDbDataUpdated(!dbDataUpdated); // toggle

  //   }
  // }
  //*/
  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      selectedCategory === null
    ) {
      return (
        <Alert severity="info">
          Select Category first...
        </Alert>
      );
    }
    // const rowToUpdate = ratingsData.find(
    //   (row) => row.chat === selectedChat
    // );
    // const updateRow = { ...rowToUpdate };
    // updateRow.category = category

    const companyOverviewData = {
      userId: userId,
      category: selectedCategory,
      chat: opinion,
      count: likes,
      entryDate: new Date(),
    };
    addEntryToTable("groupChatOverview", companyOverviewData);

    resetSort(event);
    fetchData();
    setShowForm(false);
    setSelectedCategory(null);
    setOpinion("");
    setDbDataUpdated(!dbDataUpdated); // toggle;
  };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ column: column, order: "desc" });
    }
  };

  function resetSort(event) {
    setSort({ column: "Count", order: "desc" });
  }

  function handleCategoryChange(event, newValue) {
    console.log("Selected:", newValue)
    setSelectedCategory(newValue);
  }

  async function incrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].count += 1;
    setLikes(newResults[index].count);

    setResults(newResults);
    setLikesUpdated(!likesUpdated); //toggle

    const docRef = doc(db, "groupChatOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { count: newResults[index].count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  async function decrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].count -= 1;
    setLikes(newResults[index].count);

    setResults(newResults);

    setLikesUpdated(!likesUpdated); //toggle
    const docRef = doc(db, "groupChatOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { count: newResults[index].count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  const handleMouseEnter = (index) => {
    const currentPageIndex = page * rowsPerPage;
    const rowIndex = currentPageIndex + index;
    const rowData = sortedResults[rowIndex];
    setMouseRowChanged(!mouseRowChanged) //toggle
    setSelectedRow(rowData);
  };

  const handleMouseLeave = () => {
    setSelectedRow(null);
    //   setShowCard(false);
  };

  function returnFormData() {
    return (
      <Grid item xs={12}>
        <Card
          elevation={10}
          style={{
            align: "left",
            justifyContent: "flex-start",
            marginBottom: "1em",
            marginLeft: "6px",

            width: "1300px",
          }}
        >
          <form justifyContent="flex-start" onSubmit={handleSubmit}>
            <Box>
              <Stack
                marginTop="8px"
                marginBottom="1px"
                marginLeft="3px"
                spacing={1}
              >
                {/* {getRatings()} */}
                <Autocomplete
                  value={selectedCategory}
                  variant="filled"
                  style={{ marginLeft: "3px", marginTop: "1em", width: 250 }}
                  color="default"
                  options={categories}
                  onChange={handleCategoryChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" variant="filled" />
                  )}
                ></Autocomplete>
                {selectedCategory && (
                  <FormControl marginLeft="3px">
                    <Stack marginTop="8px" marginLeft="0px" spacing={3}>
                      <TextField
                        label="Chat:"
                        style={{ marginLeft: "3px", width: "768px" }}
                        textAlign="justify"
                        inputProps={{ maxlength: 300, wrap: "hard" }}
                        variant="outlined"
                        multiline="true"
                        value={opinion}
                        onChange={(event) => setOpinion(event.target.value)}
                        helperText={`${opinion.length}/300`}
                      />
                    </Stack>
                  </FormControl>
                )}
              </Stack>
            </Box>
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 80,
                margin: "0 0 0 4px",
                backgroundColor: "#5B76D7",
                color: "white",
                marginLeft: "3px",
              }}
              type="submit"
            >
              Submit
            </Button>
            <div style={{ height: "1em" }}></div>
          </form>
        </Card>
      </Grid>
    );
  }

  const handleRateChatClick = () => {

    if (userId) {
      setShowForm(!showForm);
    } else {
      setShowLoginAlert(true);
      // setDialog1(true);
    }
  };

  const MyDialog = ({ message, onClose }) => {
    return (
      <>

        <Snackbar
          open
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            marginTop: "100px"
          }}
        >
          <Alert onClose={handleClose} severity="error">
            <span style={{ fontSize: 16, fontStyle: 'italic' }}>Only the originator can delete...</span>
          </Alert>
        </Snackbar>
      </>
    );
  };

  const handleClose = () => {
    if (showLoginAlert) setShowLoginAlert(false);
    if (showDelDisallowAlert) setShowDelDisallowAlert(false);
    // if (dialog1) setDialog1(false);
    // if (dialog2) setDialog2(false);
  };

  return (
    <>
      <Box marginTop={2}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <Stack
              spacing={3}
              maxWidth="860px"
              direction="row"
              marginTop={2}
              marginBottom={3}
            >
              <Button
                variant="contained"
                backgroundcolor="#333"
                // endIcon={<SendIcon backgroundColor="grey" />}
                onClick={handleRateChatClick}
                style={{
                  border: "none",
                  width: "130px",
                  marginLeft: "8px",
                  borderRadius: 28,
                  color: "black",
                  backgroundColor: "#D1D1D1",
                }}
              >
                RATE, CHAT
              </Button>
              <br />
            </Stack>
            {showLoginAlert && (
              <Snackbar
                open
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  marginTop: "100px"
                }}
              >
                <Alert onClose={handleClose} severity="error">
                  <span style={{ fontSize: 16, fontStyle: 'italic' }}>Login first...</span>
                </Alert>
              </Snackbar>
            )}
          </Grid>
          {showForm && returnFormData()}

          <Grid container spacing={9} direction="row">
            <Grid item xs={6}>
              <Card
                elevation={10}
                sx={{
                  width: "1350px",
                  marginLeft: "15px",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ overflow: "auto", marginTop: "0.5em" }}
                >
                  <Table
                    size="small"
                    sx={{ width: "90%", tableLayout: "fixed" }}
                  >
                    <TableHead
                      sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                    >
                      <TableRow dense>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "15%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Admin
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("userId")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("userId")}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "20%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Category
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("category")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("category")}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          align="justify"
                          sx={{
                            width: "45%",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Sort by Likes">
                            <span>
                              Chat
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("count")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("count")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "20%",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Date
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("entryDate")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("entryDate")}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell >count</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}
                    >
                      {dataToDisplay.map((result, index) => (
                        <TableRow
                          hover
                          key={index}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <TableCell>{result.userId}</TableCell>
                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "break-word" }}
                          >
                            {result.category}
                          </TableCell>

                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "nowrap" }}
                          // onClick={(event) => PopoverHandler(event, result)}
                          >
                            {result.chat}
                          </TableCell>

                          <TableCell
                            align="justify"
                            sx={{ wordWrap: "nowrap" }}
                          >
                            {result.entryDate.toDate().toLocaleString(
                              "en-US",
                              dateOptions
                            )}
                          </TableCell>

                          {/* <TableCell>{result.count}</TableCell> */}

                          <TableCell>
                            <Stack spacing={0.25} direction="row" align="top">
                              <IconButton onClick={() => incrementCount(index)}>
                                <ThumbUp />
                              </IconButton>
                              <IconButton onClick={() => decrementCount(index)}>
                                <ThumbDown />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                            {showDelDisallowAlert && (
                              <MyDialog
                                message="Only the originator can delete..."
                                onClose={handleClose}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={sortedResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {!userId && (
          <Alert severity="info" action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }>
            <span style={{ fontStyle: 'italic' }}>Login to Rate CX, Chat...</span>
          </Alert>
        )}
      </Box>
    </>
  );
}

export default ProGroupChatMember;
