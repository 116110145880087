import MarkKelly from "../picsbio/MarkKelly.jpg";
import JDVance from "../picsbio/JDVance.jpg";
import KamalaHarris from "../picsbio/KamalaHarris.jpg";
import AmiBera from "../picsbio/AmiBera.jpg";
import AOC from "../picsbio/AOC.jpg";
import Desantis from "../picsbio/Desantis.jpg";
import IlhanOmar from "../picsbio/IlhanOmar.jpg";
import TimWalz from "../picsbio/TimWalz.jpg";
import Jayapal from "../picsbio/Jayapal.jpg";
import JT from "../picsbio/JT.jpg";
import KevinMcCarthy from "../picsbio/KevinMcCarthy.jpg";
import Pelosi from "../picsbio/Pelosi.jpg";
import RajaKrish from "../picsbio/RajaKrish.jpg";
import RoKhanna from "../picsbio/RoKhanna.jpg";
import TedCruz from "../picsbio/TedCruz.jpg";
import Trump from "../picsbio/Trump.jpg";

export const states = [
  "Arizona",
  "California",
  "Florida",
  "Illinois",
  "Minnesota",
  "New York",
  "Ohio",
  "Texas",
  "Washington",
  "Quebec",
];

export const stateOptions = [
  { name: "Arizona", lawmaker: ["Mark Kelly"] },
  { name: "Ohio", lawmaker: ["JD Vance"] },
  { name: "California", lawmaker: ["Ro Khanna", "Kevin McCarthy", "Ami Bera", "Nancy Pelosi", "Kamala Harris"] },
  { name: "Florida", lawmaker: ["Ron DeSantis", "Donald Trump"] },
  { name: "Illinois", lawmaker: ["Raja Krishnamoorthi"] },
  { name: "New York", lawmaker: ["AOC"] },
  { name: "Texas", lawmaker: ["Ted Cruz"] },
  { name: "Quebec", lawmaker: ["Justin Trudeau"] },
  { name: "Washington", lawmaker: ["Pramila Jayapal"] },
  { name: "Minnesota", lawmaker: ["Ilhan Omar", "Tim Walz"] },
];

export const lawmakerKeyprops = [
  {
    lawmaker: "JD Vance",
    pic: JDVance,
    keyprop: "Wikipedia:James David Vance (born James Donald Bowman; August 2, 1984)[2][3][b] is an American politician, lawyer, author, and Marine veteran who has served since 2023 as the junior United States senator from Ohio. A member of the Republican Party, he is its nominee for vice president, as Donald Trump's running mate, in the 2024 United States presidential election. After graduating from Middletown High School, Vance joined the U.S. Marine Corps, where he served from 2003 to 2007 as a combat correspondent, with six months in Iraq. He then attended Ohio State University, graduating in 2009. He graduated from. Yale Law School in 2013. His 2016 bestselling memoir Hillbilly Elegy got considerable press attention during the 2016 election and was adapted into a feature film by Ron Howard in 2020. https://en.wikipedia.org/wiki/JD_Vance",
  },
  {
    lawmaker: "Mark Kelly",
    pic: MarkKelly,
    keyprop:
      "Wikipedia: Mark Edward Kelly (born February 21, 1964) is an American politician, former astronaut, and United States Navy Captain who has been the junior United States senator from Arizona since 2020. A member of the Democratic Party, he was elected in the special election held following the death of Senator John McCain, defeating incumbent Republican Martha McSally. Kelly flew combat missions during the Gulf War as a naval aviator before being selected as a NASA Space Shuttle pilot in 1996. He flew his first space mission in 2001 as pilot of STS-108, piloted STS-121 in 2006, and commanded STS-124 in 2008 and STS-134 in 2011, the final mission of Space Shuttle Endeavour.[3] His identical twin, Scott Kelly, is also a retired astronaut; they are the only siblings to have both traveled into orbit. https://en.wikipedia.org/wiki/Mark_Kelly",
  },
  {
    lawmaker: "Kamala Harris",
    pic: KamalaHarris,
    keyprop:
      "Wikipedia: Kamala Devi Harris (born October 20, 1964) is an American politician and attorney who is the 49th and current vice president of the United States since 2021, under President Joe Biden. She is the first female vice president and the highest-ranking female official in U.S. history, as well as the first African American and first South Asian American vice president.[5] A member of the Democratic Party, she served as a U.S. senator from California from 2017 to 2021 and the attorney general of California from 2011 to 2017. Following the withdrawal of Joe Biden from the presidential race, Harris has been described as the presumptive nominee of the Democratic Party in the 2024 presidential election. https://en.wikipedia.org/wiki/Kamala_Harris",
  },
  {
    lawmaker: "Kevin McCarthy",
    pic: KevinMcCarthy,
    keyprop:
      "Wikipedia: Kevin Owen McCarthy (born January 26, 1965) is the 55th speaker of the United States House of Representatives.[1][2][3] A member of the Republican Party, he has served as speaker since January 7, 2023. McCarthy is in his ninth House term, serving as the U.S. representative for California's 22nd congressional district from 2007 to 2013, the 23rd district from 2013 to 2023 and the 20th district since 2023. Dually, he served as House Minority Leader from 2019 to 2023 and as House Majority Leader from 2014 to 2019 under speakers John Boehner and Paul Ryan. McCarthy was born in Bakersfield, California. He attended California State University, Bakersfield, while working as a firefighter. McCarthy formerly chaired the California Young Republicans and the Young Republican National Federation. https://en.wikipedia.org/wiki/Kevin_McCarthy",
  },
  {
    lawmaker: "Ami Bera",
    pic: AmiBera,
    keyprop:
      "Wikipedia:Amerish Babulal Bera, born March 2, 1965, is an American physician and politician serving as the U.S. representative for California's 6th congressional district since 2013 (known as the 7th congressional district until 2023). A member of the Democratic Party, his district encompasses most of Sacramento's eastern and southern suburbs, including Elk Grove, Folsom, and Rancho Cordova. https://en.wikipedia.org/wiki/Ami_Bera",
  },
  {
    lawmaker: "Nancy Pelosi",
    pic: Pelosi,
    keyprop:
      "Wikipedia: Nancy Patricia Pelosi, born March 26, 1940, is an American politician who served as the 52nd speaker of the United States House of Representatives from 2007 to 2011 and again from 2019 to 2023. She represents California's 11th congressional district in the United States House of Representatives and has been in Congress since 1987. The district, numbered as the 5th district from 1987 to 1993, the 8th from 1993 to 2013, and the 12th from 2013 to 2023, includes most of the city of San Francisco. A member of the Democratic Party, Pelosi is the first woman elected Speaker and the first woman to lead a major political party in either chamber of the U.S. Congress. She led House Democrats for 20 years, from 2003 to 2023. https://en.wikipedia.org/wiki/Nancy_Pelosi",
  },
  {
    lawmaker: "Ron DeSantis",
    pic: Desantis,
    keyprop:
      "Wikipedia: Ronald Dion DeSantis, born September 14, 1978, is an American attorney, military officer, and politician serving as the 46th governor of Florida since 2019. A member of the Republican Party, DeSantis represented Florida's 6th congressional district in the U.S. House of Representatives from 2013 to 2018. Born in Jacksonville, DeSantis spent most of his childhood in Dunedin, Florida. He graduated from Yale University and Harvard Law School. DeSantis joined the United States Navy in 2004 and was promoted to lieutenant before serving as a legal advisor to SEAL Team One; he was deployed to Iraq in 2007. https://en.wikipedia.org/wiki/Ron_DeSantis",
  },
  {
    lawmaker: "Donald Trump",
    pic: Trump,
    keyprop:
      "Wikipedia: Donald John Trump, born June 14, 1946, served as the 45th president of the United States from 2017 to 2021.Trump graduated from the Wharton School of the University of Pennsylvania with a bachelor's degree in 1968. Trump's political positions have been described as populist, protectionist, isolationist, and nationalist. Trump and his businesses have been involved in more than 4,000 state and federal legal actions, including six bankruptcies. Trump's political positions have been described as populist, protectionist, isolationist, and nationalist. https://en.wikipedia.org/wiki/Donald_Trump",
  },
  {
    lawmaker: "Raja Krishnamoorthi",
    pic: RajaKrish,
    keyprop:
      "Wikipedia: Subramanian Raja Krishnamoorthi, born July 19, 1973, is an American businessman, politician, and attorney serving as the U.S. representative for Illinois's 8th congressional district since 2017. The district includes many of Chicago's western and northwestern suburbs, such as Hoffman Estates, Elgin, Schaumburg, Wood Dale, and Elk Grove Village. He was elected to succeed Tammy Duckworth, who gave up the seat to run for the U.S. Senate. A member of the Democratic Party, Krishnamoorthi serves on the House Oversight Committee and the House Permanent Select Committee on Intelligence. He also chairs the Oversight Subcommittee on Economic and Consumer Policy, and serves as an assistant whip. https://en.wikipedia.org/wiki/Raja_Krishnamoorthi",
  },
  {
    lawmaker: "AOC",
    pic: AOC,
    keyprop:
      "Wikipedia: Alexandria Ocasio-Cortez (AOC); born October 13, 1989, is an American politician and activist. She has served as the U.S. representative for New York's 14th congressional district since 2019, as a member of the Democratic Party. The district includes the eastern part of the Bronx, portions of north-central Queens, and Rikers Island in New York City. https://en.wikipedia.org/wiki/Alexandria_Ocasio-Cortez",
  },
  {
    lawmaker: "Ted Cruz",
    pic: TedCruz,
    keyprop:
      "Wikipedia: Rafael Edward Cruzis an American politician and attorney serving as the junior United States senator from Texas since 2013. A member of the Republican Party, Cruz served as Solicitor General of Texas from 2003 to 2008. After graduating from Princeton University and Harvard Law School, Cruz pursued a career in politics. He worked as a policy advisor in the George W. Bush administration before serving as Solicitor General of Texas from 2003 to 2008. In 2012, Cruz was elected to the U.S. Senate, becoming the first Hispanic-American to serve as a U.S. senator from Texas. In the Senate, Cruz has taken consistently conservative positions on economic and social policy; he played a leading role in the 2013 United States federal government shutdown, seeking to force Congress and President Barack Obama to defund the Affordable Care Act. He was reelected in a close Senate race in 2018 against Democratic candidate Beto O'Rourke. In 2016, Cruz ran for president of the United States, placing second behind Donald Trump in the Republican primaries. The competition for the Republican presidential nomination between Trump and Cruz was deeply acrimonious and characterized by a series of public personal attacks. While Cruz initially declined to endorse Trump's campaign once he won the nomination, he became a staunch Trump supporter during his presidency. After the January 2021 Capitol attack, Cruz received widespread political and popular backlash for objecting to the certification of Joe Biden's victory in the 2020 presidential election and giving credence to the false claim that the election was fraudulent. https://en.wikipedia.org/wiki/Ted_Cruz",
  },
  {
    lawmaker: "Pramila Jayapal",
    pic: Jayapal,
    keyprop:
      "Wikipedia: Pramila Jayapal, born September 21, 1965, is an American politician serving as the U.S. representative from Washington's 7th congressional district since 2017. A member of the Democratic Party, she represents most of Seattle, as well as some suburban areas of King County. Jayapal represented the 37th legislative district in the Washington State Senate from 2015 to 2017. She is the first Indian-American woman to serve in the U.S. House of Representatives. The district's first female member of Congress, she is also the first Asian American to represent Washington at the federal level. Before ente ring electoral politics, Jayapal was a Seattle-based civil rights activist.https://en.wikipedia.org/wiki/Pramila_Jayapal",
  },
  {
    lawmaker: "Justin Trudeau",
    pic: JT,
    keyprop:
      "Wikipedia: Justin Pierre James Trudeau PC MP, born December 25, 1971, is a Canadian politician who is the 23rd and current prime minister of Canada. He has served as the prime minister of Canada since 2015 and as the leader of the Liberal Party since 2013. Trudeau is the second-youngest prime minister in Canadian history after Joe Clark; he is also the first to be the child or other relative of a previous holder of the post, as the eldest son of Pierre Trudeau.Trudeau was born in Ottawa and attended Collège Jean-de-Brébeuf. He graduated from McGill University in 1994 with a Bachelor of Arts degree in literature, then in 1998 acquired a Bachelor of Education degree from the University of British Columbia. https://en.wikipedia.org/wiki/Justin_Trudeau",
  },
  {
    lawmaker: "Ro Khanna",
    pic: RoKhanna,
    keyprop:
      "Wikipedia: Rohit Khanna, born September 13, 1976, is an American politician, lawyer, and academic serving as the U.S. representative from California's 17th congressional district since 2017. A member of the Democratic Party, he defeated eight-term incumbent Democratic Representative Mike Honda in the general election on November 8, 2016, after first running for the same seat in 2014. Khanna also served as the deputy assistant secretary in the United States Department of Commerce under President Barack Obama from August 8, 2009, to August 2011. Khanna identifies as a progressive capitalist, and has called for a 'new economic patriotism' as a governing philosophy. He states that he only accepts campaign donations from individuals and is one of only six members of the U.S. House of Representatives, and ten members of Congress, who state that they do not take campaign contributions from political action committees (PACs) or corporations. https://en.wikipedia.org/wiki/Ro_Khanna",
  },
  {
    lawmaker: "Ilhan Omar",
    pic: IlhanOmar,
    keyprop:
      "Wikipedia: lhan Abdullahi Omar, born October 4, 1982, is an American politician serving as the U.S. representative for Minnesota's 5th congressional district since 2019. She is a member of the Democratic–Farmer–Labor Party.[6] Before her election to Congress, Omar served in the Minnesota House of Representatives from 2017 to 2019, representing part of Minneapolis. Her congressional district includes all of Minneapolis and some of its first-ring suburbs. Omar serves as whip of the Congressional Progressive Caucus and has advocated for a $15 minimum wage, universal healthcare, student loan debt forgiveness, the protection of Deferred Action for Childhood Arrivals, and abolishing U.S. Immigration and Customs Enforcement (ICE). A frequent critic of Israel, Omar supports the Boycott, Divestment and Sanctions (BDS) movement and has denounced its settlement policy and military campaigns in the occupied Palestinian territories, as well as what she describes as the influence of pro-Israel lobbies. Omar is the first Somali American and the first naturalized citizen of African birth in the United States Congress, and the first woman of color to represent Minnesota. https://en.wikipedia.org/wiki/Ilhan_Omar",
  },

  {
    lawmaker: "Tim Walz",
    pic: TimWalz,
    keyprop:
      "Wikipedia: Timothy James Walz, born April 6, 1964) is an American politician, former schoolteacher, and retired U.S. Army non-commissioned officer who has served as the 41st governor of Minnesota since 2019. He is the Democratic Party's nominee for vice president in the 2024 United States presidential election. He was a member of the U.S. House of Representatives from 2007 to 2019, and was the ranking member of the House Veterans Affairs Committee from 2017 to 2019."
  }
];
export const category = [
  "Resolves Issues",
  "Panders to Special Interests",
  "Trustworthy",
  "Conduct",
  "Competent",
];

export const supportedRatingOptions = {
  AorB: 'AorB',
  yesNo: 'yesNo',
  fiveStars: '5Stars',
  agreeDisagree: 'agreeDisagree',
  quality: 'quality'
}
export const ratingOptions = [
  "❓A or B",
  "✔️Yes, ❌No",
  "✔️Agree, ❌Disagree",
  "⭐️ ... ⭐️⭐️⭐️⭐️⭐️",
  "😞Terrible, 😔Bad, 😐Average, 🙂Good, 😃Excellent",
  // "🐢Very Slow, 🐇Slow, 🦊Average, 🐆Fast, 🦅Very Fast",
];

export const ratingOptionsEdu = [
  // "❓A",
  // "❓B",
  // "✔️Yes",
  // "❌No",
  "✔️Agree",
  "❌Disagree"
];
export const ratingOpinions = [
  "Fair, Balanced, Credible",
  "Clarity of Thought",
  "Concise, Clear Expression",
  "Delivery/Style - Engaging",
  "English - Impeccable",
];

export const ratingMusic = [
  "Emotion, Mood",
  "Accompanists",
  "Lasting Listening Pleasure",
];

// export const ratingOptions = [
//   { label: '⭐️⭐️⭐️⭐️⭐️', value: '5-star', fontSize: '12px' },
//   { label: '⭐️⭐️⭐️⭐️', value: '4-star', fontSize: '12px' },
//   { label: '⭐️⭐️⭐️', value: '3-star', fontSize: '12px' },
//   { label: '⭐️⭐️', value: '2-star', fontSize: '12px' },
//   { label: '⭐️', value: '1-star', fontSize: '12px' },
//   { label: '🐢', value: 'vSlow', fontSize: '12px' },
//   { label: '🐇', value: 'slow', fontSize: '12px' },
//   { label: '🦊', value: 'average', fontSize: '12px' },
//   { label: '🐆', value: 'fast', fontSize: '12px' },
//   { label: '🦅', value: 'vFast', fontSize: '12px' },
//   { label: '😞', value: 'terrible', fontSize: '12px' },
//   { label: '😔', value: 'bad', fontSize: '12px' },
//   { label: '😐', value: 'average', fontSize: '12px' },
//   { label: '🙂', value: 'good', fontSize: '12px' },
//   { label: '😃', value: 'excellent', fontSize: '12px' },
// ];

export const questionCategory = [
  "US Politics",
  "World Issues",
  "Media/Journalism",
  "Finance/Economy",
  "Quirky",
  "Social",
  "Sports",
  "Tech"
]

export const CXCategory = [
  "Quality",
  "Price",
  "Customer Service",
  "Responsiveness",
  "Premises/Facilities"
]
export const CXCategoryBanking = [
  "Charges",
  "Credit Cards/Loans",
  "Customer Service, Call Center, Virtual Asst.",
  "ATM Availability",
  "Branch Facilities"
]
export const CXCategoryInsurance = [
  "Charges",
  "Claim Payment",
  "Customer Service, Call Center, Virtual Asst.",
  "Website/App UI",
]

export const CXCategoryFood = [
  "Crave Factor",
  "Deliciousness",
  "Restaurant Ambiance",
  "Delivery",
  "Price",
]

export const CXCategoryTechCX = [
  "UI Ease of Use",
  "UI Look/Appearance",
]
export const CXCategoryMedia = [
  "Bias - Agenda-driven",
  "Balance",
  "Credibiity",
  "Independence",
  "Content Quality/Delivery"
]

export const opCategories = [
  "Current Affairs",
  "Economy & Finance",
  "Nutrition and Lifestyle",
  "Media",
  "Spirtuality",
  "Technology",
  "Quirky"
]
export const Business = [
  "Banking",
  // "Car Rental",
  // "Cosmetics",
  // "Gas",
  "Food",
  // "FMCG",
  "Insurance",
  "Media",
  "Mobile Services",
  "Pharma, Cosmetics",
  "Retail",
  // "Real Estate",
  "Tech CX",
  // "Utilities",
]

export const businessOptions = [
  { business: "Banking", name: ["Wells Fargo"] },
  { business: "Mobile Services", name: ["AT&T"] },
  { business: "Media", name: ["New York Times", "Al Jazeera", "BBC"] },
  { business: "Utilities", name: ["Con Edison"] },
  { business: "Tech CX", name: ["Trupollz"] },
  { business: "Food", name: ["McDonald's"] },
  { business: "Insurance", name: ["Allstate"] },
];

export const companyKeyprops = [
  {
    company: "Trupollz",
    keyprop: "www.trupollz.com",
  },

  {
    company: "NYT",
    keyprop: "www.nyt.com",
  },
  {
    company: "AT&T",
    keyprop: "www.att.com",
  },
  {
    company: "Con Edison",
    keyprop: "www.coned.com",
  },
  {
    company: "Wells Fargo",
    keyprop: "www.wellsfargo.com",
  },
  {
    company: "McDonald's",
    keyprop: "www.mcdonalds.com",
  },
  {
    name: "AllState",
    keyprop: "www.allstate.com",
  },
]
export const targetExample = [
  "New York Times",
  "BBC",
  "Washington Post",
  "Al Jazeera",
  "CNN",
  "Fox News",
  "YouTube",
  "facebook",
  "WhatsApp",
  "...",
]
