import React, { useState, useEffect } from "react";

import {
  Card,
  Box,
  Typography,
  Grid,
  TextField,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';

import {
  ratingOptionsEdu,
} from "./MyData";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { collection, where, query, addDoc, getDocs, doc, updateDoc } from "firebase/firestore"

function ProGroupChatNew(props) {
  const { userId, db, groupChatTitle, groupChatId } = props;
  const [loading, setLoading] = React.useState(false);
  // const [userId, setUserId] = useState(props.userId);
  // const [text, setText] = useState('');
  // const [selectedOption, setSelectedOption] = useState('');
  // const [correctOption, setCorrectOption] = useState('');

  const cat = ['Wedding Planner', 'Venue, Related', 'Food, Drinks', '# Guests', 'Budget']
  const [categories, setCategories] = useState(
    Array.from({ length: 5 }).map(() => ({
      category: ''
    }))
  );
  const handleChange = (index, field, value) => {
    const newCategories = [...categories];
    newCategories[index][field] = value;
    setCategories(newCategories);
  };

  const [groupChatCategory, setGroupChatCategory] = React.useState("");
  // const options = ['Option 1', 'Option 2', 'Option 3'];

  // const [question, setQuestion] = React.useState("");
  const [showForm, setShowForm] = React.useState(true)
  const [binaryRespQuestFlag, setBinaryRespQuestFlag] = React.useState(false)

  const [QuizResults, setQuizResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedRatingOption, setSelectedRatingOption] = React.useState(null);
  const [shownSelectedRatingOption, setShownSelectedRatingOption] =
    React.useState(null);
  const [optionA, setOptionA] = React.useState("");
  const [optionB, setOptionB] = React.useState("")

  const [questCategorySelected, setQuestCategorySelected] = React.useState(false);
  const [entryDate, setEntryDate] = useState(new Date());

  const handleEntryDateChange = (datePicked) => {
    let date = datePicked.toDate();
    setEntryDate(date);
  };

  // const handleEntryDateChange = (date) => {
  //   const entryString = date.toISOString();
  //   setEntryDate(entryString);
  // };

  const filterResults = (results) => {
    return results.filter((row) => row.userId === props.userId);
  };

  useEffect(() => {

  }, [entryDate]);

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // let quest = question;

    // if (selectedRatingOption === 'AorB') {
    //   quest = optionA + "   or   " + optionB + "?"
    // }
    // const entryDate = new Date();

    const groupChatTableData = {
      categories: categories,
      entryDate: entryDate,
    };

    // addEntryToTable('groupChatTable', groupChatTableData);

    const groupChatQuery = query(collection(db, 'groupChatTable'),
      where('groupChatId', '==', groupChatId),
    );
    const docRef = doc(db, 'groupChatTable', groupChatId);
    await updateDoc(docRef, groupChatTableData);
    console.log('Document updated successfully');

    const pendingVotesTableData = {
      pendingVotesTableId: groupChatId,
      userId: userId
    }
    addEntryToTable('pendingVotesTable', pendingVotesTableData);
    setQuestCategorySelected(false);
    setSelectedCategory("");
    setShownSelectedRatingOption(null);
    setCategories(Array.from({ length: 5 }).map(() => ({
      category: ''
    })));
    setEntryDate(new Date());
    setShowForm(false)
    setBinaryRespQuestFlag(false)
    setLoading(false);
  };

  function handleChangeGroupChatCategory(title) {
    setGroupChatCategory(title)
  }

  return (
    <>
      {showForm && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card elevation={10}
              sx={{
                marginTop: '15px',
                marginLeft: '8px', width: "550px", padding: "6px",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <div style={{ display: "flex", alignItems: "left", minWidth: '250px' }}>
                <FormControl fullWidth sx={{ minWidth: 200 }}>
                   <TextField
                    label={`Enter chat category, e.g., Thai Weddings`}
                    variant="outlined"
                    rows={1}
                    value={groupChatCategory}
                    onChange={(e) => handleChangeGroupChatCategory(e.target.value)}
                  /> 
                </FormControl>
              </div> */}
              <Stack sx={{ marginRight: '10px' }}>
                {categories.map((row, index) => (
                  <Grid container spacing={.25} key={index} style={{ marginTop: '4px', marginLeft: '0px' }}>
                    <Grid item xs={10}>
                      <FormControl fullWidth sx={{ minWidth: 360 }}>
                        <TextField
                          label={`Enter category, e.g., ${cat[index]}...`}
                          variant="outlined"
                          rows={1}
                          value={row.cat}
                          onChange={(e) => handleChange(index, 'category', e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={2}>
                    <FormControl fullWidth sx={{ minWidth: 100 }} >
                      <Select
                        value={row.correctAnswer}
                        onChange={(e) => handleChange(index, 'correctAnswer', e.target.value)}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>Select the correct answer</MenuItem>
                        {ratingOptionsEdu.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                    {/* <Grid item xs={2}>
                    <FormControl fullWidth sx={{ minWidth: 100 }}>
                      <TextField
                        label={`Pick correct answer`}
                        variant="outlined"
                        rows={1}
                        value={row.answer}
                        onChange={(e) => handleChange(index, 'answer', e.target.value)}
                      />
                    </FormControl> */}
                    {/* <Select
                      value={row.correctOption}
                      onChange={(e) => handleChange(index, 'correctOption', e.target.value)}
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>Select Correct Option</MenuItem>
                      {ratingOptionsEdu.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </Grid>
                ))}
              </Stack>
              <Stack spacing={.75} direction='column' style={{ marginTop: '4px' }}>
                {/* <Grid item xs={2}> */}
                {/* <FormControl>
                  <DatePicker
                    label="Date"
                    onChange={(value) => handleEntryDateChange(value)}
                    sx={{ width: "150px" }}
                    clearText="Clear"
                    cancelText="Cancel"
                    todayText="Today"
                    showTodayButton
                  />
                </FormControl> */}
                <Button variant="contained" width="120px" color="primary" onClick={handleSubmit} sx={{ minWidth: '120px' }}>
                  SAVE
                </Button>
                {loading && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', height: '5vh' }}>
                    <CircularProgress color="primary" size={75} />
                  </Box>
                )}
              </Stack>
            </Card>
          </LocalizationProvider >
        </>
      )
      }
    </>
  );
}
export default ProGroupChatNew;
